import React, { Component, Fragment } from 'react'

// Components
import Header from '../../components/header'
import Section from '../../components/section'
import Footer from '../../components/footer'

class Home extends Component {

  render() {
    return (
      <Fragment>
        <Header />
        <Section />
        <Footer />
      </Fragment>
    )
  }
}

export default Home