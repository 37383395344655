import React, { Component } from 'react'

// Styles
import styles from './footer.module.css'

// Texts
import { footer } from '../../config/textConfig.json'

class Footer extends Component {

  render() {
    return (
      <div
        className={ styles.footer }
      >
        <div
          className={ styles.footerInfoContainer }
        >
          <a
            className={ styles.footerInfoMail }
            href="mailto:info@naturaheroes.com?Subject=Me%20gustaría%20saber%20más"
          >
            { footer.infoMail }
          </a>
          <p>
            { footer.infoText }
          </p>
        </div>
      </div>
    )
  }
}

export default Footer