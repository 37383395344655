import React, { Component } from 'react'

// Styles
import styles from './section.module.css'

// Components
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import DateCountdown from 'react-date-countdown-timer'
// import Countdown from '../countdown'

// Text
import { mainSection } from '../../config/textConfig.json'

// Images
import natureOne from '../../assets/images/nature1.jpg'
import natureTwo from '../../assets/images/nature2.jpg'
import natureThree from '../../assets/images/nature5.jpg'
import natureFour from '../../assets/images/jungle.jpg'

class Section extends Component {
  render() {
    return (
      <div className={styles.mainSection}>
        <Container fluid>
          <Row>
            <Col md={6}>
              <div className={styles.mainTextContainer}>
                <p className={styles.mainTextTitle}>{mainSection.title}</p>
                <p className={styles.mainText}>{mainSection.text}</p>
                <DateCountdown
                  dateTo='January 31, 2021 00:00:00 GMT+03:00'
                  numberOfFigures={5}
                  locales={['Año', 'Mes', 'Día', 'Hora', 'Min', 'Seg']}
                  locales_plural={[
                    'Años',
                    'Meses',
                    'Días',
                    'Horas',
                    'Min',
                    'Seg',
                  ]}
                  //callback={() => alert('Hello')}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className={styles.mainPicsContainer}>
                <Image className={styles.mainPic} src={natureOne} thumbnail />
                <Image className={styles.mainPic} src={natureTwo} thumbnail />
                <Image className={styles.mainPic} src={natureThree} thumbnail />
                <Image className={styles.mainPic} src={natureFour} thumbnail />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Section
