import React, { Component } from 'react'

// Bootstrap Components
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Image from 'react-bootstrap/Image'

// Styles
import styles from './header.module.css'

// Images
import logo from '../../assets/images/natura_heroes_logo.png'
import instagramLogo from '../../assets/images/instagram-logo.png'
// import facebookLogo from '../../assets/images/facebook-logo.png'

class Header extends Component {

  render() {
    return (
      <Navbar
        className={ styles.headerBar }
      >
        <Navbar.Brand
          className={styles.mainLogoContainer}
        >
          <Image
            src={logo}
            className={styles.mainLogo}
            fluid
          />
        </Navbar.Brand>
        <Navbar.Brand
          href="#home"
          className={styles.headerBrandName}
        >
          {'natura heroes'}
        </Navbar.Brand>
        <Nav>
          <Nav.Link href="https://www.instagram.com/naturaheroes/">
            <Image
              src={instagramLogo}
              className={styles.instaLogo}
              fluid
            />
          </Nav.Link>
          {/* <Nav.Link href="#home">            
            <Image
              src={facebookLogo}
              className={styles.instaLogo}
              fluid
            />
          </Nav.Link> */}
        </Nav>
      </Navbar>
    )
  }
}

export default Header