import React from 'react';

// Components
import Home from './modules/home'

function App() {
  return <Home />
}

export default App
